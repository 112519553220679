import { mapObject } from '@/utils/index';
import { AUTH_WORK_REQUIRED_ITEMS } from '@/config/constants';

import JobCardItemTyre from './JobCardItemTyre';
import JobCardItemBrake from './JobCardItemBrake';
import JobCardItemBattery from './JobCardItemBattery';
import JobCardItemShock from './JobCardItemShock';
import JobCardItemOther from './JobCardItemOther';
import JobCardItemExhausts from './JobCardItemExhausts';
import WorkItem from './WorkItem';

export default class JobCard {
  constructor(existingObject) {
    const object = existingObject || {};

    const sections = object.sections || {
      batteries: [],
      brakes: [],
      other: [],
      shocks: [],
      tyres: [],
      exhausts: [],
    };

    sections.batteries = mapObject(sections.batteries, JobCardItemBattery);
    sections.brakes = mapObject(sections.brakes, JobCardItemBrake);
    sections.other = mapObject(sections.other, JobCardItemOther);
    sections.shocks = mapObject(sections.shocks, JobCardItemShock);
    sections.tyres = mapObject(sections.tyres, JobCardItemTyre);
    sections.exhausts = mapObject(sections.exhausts, JobCardItemExhausts);

    this.jobNumber = object.jobNumber || undefined;
    this.appointmentDate = object.appointmentDate || undefined;
    this.appointmentTime = object.appointmentTime || undefined;
    this.estimatedCompletionDate = object.estimatedCompletionDate || undefined;
    this.estimatedCompletionTime = object.estimatedCompletionTime || undefined;
    this.workRequiredSelection = object.workRequiredSelection || [new WorkItem(AUTH_WORK_REQUIRED_ITEMS.default)];
    this.sections = sections;
    this.serviceAdvisorName = object.serviceAdvisorName || undefined;
    this.serviceAdvisorContactNumber = object.serviceAdvisorContactNumber || undefined;
    this.authorisationLetterEmail = object.authorisationLetterEmail || undefined;
    this.authorisationLetterEmailConfirmed = object.authorisationLetterEmailConfirmed !== undefined ? object.authorisationLetterEmailConfirmed : false;
    this.workComplete = object.workComplete !== undefined ? object.workComplete : false;
    this.preAuthorisationNumber = object.preAuthorisationNumber || undefined;
    this.jobComments = object.jobComments || undefined;
  }

  sectionsToString() {
    Object.keys(this.sections).forEach((sectionKey) => {
      const section = this.sections[sectionKey];

      section.forEach((sectionItem) => {
        const item = sectionItem;
        item.toFixedAmounts();
      });
    });
  }
}
