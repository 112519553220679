import { mapObject } from '@/utils/index';
import ProductSetupItemValueAdd from './ProductSetupItemValueAdd';
import MerchantSetupItemValueAdd from './MerchantSetupItemValueAdd';

export default class ProductSetup {
  constructor(existingObject) {
    const object = existingObject || {};
    const sections = object.sections || {
      products: [],
      merchants: [],

    };
    sections.products = mapObject(sections.products, ProductSetupItemValueAdd);
    sections.merchants = mapObject(sections.merchants, MerchantSetupItemValueAdd);
    this.cardRequired = object.iscardRequired || '';
    this.truckFuelNet = object.truckFuelNet || '';
    this.primaryCardType = object.primaryCardType || '';
    this.secondaryCardType = object.secondaryCardType || '';
    this.oilCompanyCard = object.oilCompanyCard || '';
    this.clientSubsidy = object.clientSubsidy || '';
    this.oilSubsidy = object.oilSubsidy || '';
    this.walletRequired = object.walletRequired || '';
    this.smashGrab = object.smashGrab || '';
    this.ignoreFixedCost = object.ignoreFixedCost || '';
    this.bdfleetMoveRfid = object.bdfleetMoveRfid || '';
    this.driverToPayInHouse = object.driverToPayInHouse || '';
    this.budgetOption = object.budgetOption || '';
    this.ortCountry = object.ortCountry || '';
    this.registerForOrt = object.registerForOrt || '';
    this.ortRegion = object.ortRegion || '';
    this.ortVehicleClass = object.ortVehicleClass || '';
    this.ortAxleClass = object.ortAxleClass || '';
    this.dualClass = object.dualClass || '';
    this.adminFeeGroup = object.adminFeeGroup || '';
    this.authorityGroup = object.authorityGroup || '';
    this.nationalContributionL = object.nationalContributionL || '';
    this.nationalContributionFuelCPK = object.nationalContributionFuelCPK || '';
    this.nationalContributionRMCPK = object.nationalContributionRMCPK || '';
    this.maintenanceNumber = object.maintenanceNumber || '';
    this.exceptionGroup = object.exceptionGroup || '';
    this.replacementKilometerGroup = object.replacementKilometerGroup || '';
    this.replacementMonthGroup = object.replacementMonthGroup || '';
    this.systemDeal = object.systemDeal || '';
    this.system4DealNumber = object.system4DealNumber || '';
    this.system4SerialNumber = object.system4SerialNumber || '';
    this.system4EngineNumber = object.system4EngineNumber || '';
    this.system4Registration = object.system4Registration || '';
    this.monthlyTotalLimitAmount = object.monthlyTotalLimitAmount || '';
    this.monthLitreLimit = object.monthLitreLimit || '';
    this.monthFuelAmount = object.monthFuelAmount || '';
    this.monthRmAmount = object.monthRmAmount || '';
    this.merchantLimit = object.merchantLimit || '';
    this.transactionLimit = object.transactionLimit || '';
    this.ortAllowed = object.ortAllowed || '';
    this.vaProductDescription = object.vaProductDescription || '';
    this.vaAction = object.vaAction || '';
    this.sections = sections;
  }
}
