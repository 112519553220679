import Location from './Location';
import RecoveryVehicleDetails from './RecoveryVehicleDetails';

export default class TowingAssistanceDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.towType = object.towType || undefined;
    this.towTypeDescription = object.towTypeDescription || undefined;
    this.isAccessible = object.isAccessible !== undefined ? object.isAccessible : false;
    this.inaccessibilityReason = object.inaccessibilityReason || undefined;
    this.destinationLocation = new Location(object.destinationLocation);
    this.recoveryVehicleDetails = new RecoveryVehicleDetails(object.recoveryVehicleDetails);
  }
}
