export default class Location {
  constructor(existingObject) {
    const object = existingObject || {};

    this.addressLine1 = object.addressLine1 || undefined;
    this.addressLine2 = object.addressLine2 || undefined;
    this.suburb = object.suburb || undefined;
    this.city = object.city || undefined;
    this.province = object.province || undefined;
    this.country = object.country || undefined;
    this.longitude = object.longitude || undefined;
    this.latitude = object.latitude || undefined;
    this.locationAdditionalInformation = object.locationAdditionalInformation || undefined;
    this.zoneType = object.zoneType || undefined;
    this.zoneName = object.zoneName || undefined;
  }
}
