import ReferenceList from './ReferenceList';
import Diagnosis from './Diagnosis';
import Vehicle from './Vehicle';
import Customer from './Customer';
import VehicleDetails from './VehicleDetails';
import DriverDetails from './DriverDetails';
import ManagedAuthortisation from './ManagedAuthorisation';
import Merchant from './Merchant';
import Audit from './Audit';
import Client from './Client';
import Authorisation from './Authorisation';
import MerchantDetails from './MerchantDetails';

import { SEVERITIES } from '../config/constants';
import AuthorisationDetail from './AuthorisationDetail';
import FundAllocationDetails from './fundAllocation/FundAllocationDetails';
import VehicleCaptureDetails from './vehicleCapture/VehicleCaptureDetails';

export default class ServiceRequest {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || '';
    this.serviceRequestNumber = object.serviceRequestNumber || undefined;
    this.externalReferenceId = object.externalReferenceId || undefined;
    this.externalReferenceType = object.externalReferenceType || undefined;
    this.externalReferenceStatus = object.externalReferenceStatus || undefined;
    this.scheme = object.scheme || undefined;
    this.applicationType = new ReferenceList(object.applicationType);
    this.serviceType = new ReferenceList(object.serviceType);
    this.requestType = new ReferenceList(object.requestType);
    this.status = new ReferenceList(object.status);
    this.state = new ReferenceList(object.state);
    this.createdTimestamp = object.createdTimestamp || undefined;
    this.lastModifiedTimestamp = object.lastModifiedTimestamp || undefined;
    this.closedTimestamp = object.closedTimestamp || undefined;
    this.isSecurityRequired = object.isSecurityRequired !== undefined ? object.isSecurityRequired : false;
    this.callerType = object.callerType || undefined;
    this.diagnosis = new Diagnosis(object.diagnosis);
    this.vehicle = new Vehicle(object.vehicle);
    this.customer = new Customer(object.customer);
    this.vehicleDetails = new VehicleDetails(object.vehicleDetails);
    this.driverDetails = new DriverDetails(object.driverDetails);
    this.currentTask = object.currentTask || 'Pending';
    this.severity = object.severity || SEVERITIES.LOW.value;
    this.client = new Client(object.client);
    this.authorisation = new Authorisation(object.authorisation);
    this.authorisationDetail = new AuthorisationDetail(object.authorisationDetail);
    this.managedAuthorisation = new ManagedAuthortisation(object.managedAuthorisation);
    this.merchant = new Merchant(object.merchant);
    this.audit = new Audit(object.audit);
    this.merchantDetails = new MerchantDetails(object.merchantDetails);
    this.fundAllocation = new FundAllocationDetails(object.fundAllocation);
    this.vehicleCapture = new VehicleCaptureDetails(object.vehicleCapture);
  }
}
