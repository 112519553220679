import MerchantSetupItemBase from './MerchantSetupItemBase';

export default class MerchantSetupItemValueAdd extends MerchantSetupItemBase {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || {};

    this.merchantCode = object.merchantCode || undefined;
  }
}
