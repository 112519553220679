export default class Audit {
  constructor(existingObject) {
    const object = existingObject || {};

    this.createdBy = object.createdBy || undefined;
    this.createdById = object.createdById || undefined;
    this.comments = object.comments || undefined;
    this.event = object.event || undefined;
    this.auditLog = object.auditLog || [];
    this.createdByEmail = object.createdByEmail || undefined;
  }
}
