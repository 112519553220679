export default class MaintananceContractSetup {
  constructor(existingObject) {
    const object = existingObject || {};

    this.contractType = object.contractType || '';
    this.billingType = object.billingType || '';
    this.sponsorshipAmount = object.sponsorshipAmount || '';
    this.actualContractEndDate = object.actualContractEndDate || '';
    this.categoryCode = object.categoryCode || '';
    this.categoryCode1 = object.categoryCode1 || '';
    this.categoryCode2 = object.categoryCode2 || '';
    this.categoryCode3 = object.categoryCode3 || '';
    this.categoryCode4 = object.categoryCode4 || '';
    this.categoryCode5 = object.categoryCode5 || '';
    this.categoryLimitation = object.categoryLimitation || '';
    this.categoryLimitCode1 = object.categoryLimitCode1 || '';
    this.categoryLimitCode2 = object.categoryLimitCode2 || '';
    this.categoryLimitCode3 = object.categoryLimitCode3 || '';
    this.categoryLimitCode4 = object.categoryLimitCode4 || '';
    this.categoryLimitCode5 = object.categoryLimitCode5 || '';
    this.frontAllow1 = object.frontAllow1 || '';
    this.frontAllow2 = object.frontAllow2 || '';
    this.frontAllow3 = object.frontAllow3 || '';
    this.frontAllow4 = object.frontAllow4 || '';
    this.frontAllow5 = object.frontAllow5 || '';
    this.rearAllow1 = object.rearAllow1 || '';
    this.rearAllow2 = object.rearAllow2 || '';
    this.rearAllow3 = object.rearAllow3 || '';
    this.rearAllow4 = object.rearAllow4 || '';
    this.rearAllow5 = object.rearAllow5 || '';
    this.otherAllow1 = object.tagAllow1 || '';
    this.tagAllow2 = object.tagAllow2 || '';
    this.tagAllow3 = object.tagAllow3 || '';
    this.tagAllow4 = object.tagAllow4 || '';
    this.tagAllow5 = object.tagAllow5 || '';
    this.otherAllow1 = object.otherAllow1 || '';
    this.otherAllow2 = object.otherAllow2 || '';
    this.otherAllow3 = object.otherAllow3 || '';
    this.otherAllow4 = object.otherAllow4 || '';
    this.otherAllow5 = object.otherAllow5 || '';
    this.currency = object.currency || '';
    this.currencyExchangeRate = object.currencyExchangeRate || '';
    this.inflationIndicator = object.inflationIndicator || '';
    this.modelJob = object.modelJob || '';
    this.standardKilometerEnd = object.standardKilometerEnd || '';
    this.extendedKilometerStart = object.extendedKilometerStart || '';
    this.extendedKilometerEnd = object.extendedKilometerEnd || '';
    this.cpkRateApplies = object.cpkRateApplies || '';
    this.cpkRate = object.cpkRate || '';
    this.cpkCurrentOdometer = object.cpkCurrentOdometer || '';
    this.cpkOpeningOdometer = object.cpkOpeningOdometer || '';
    this.standardRate = object.standardRate || '';
    this.cpkCurrentDate = object.cpkCurrentDate || '';
    this.rateAmount = object.rateAmount || '';
    this.addVehicleMessage = object.addVehicleMessage || '';
    this.messageLine1 = object.messageLine1 || '';
    this.messageLine2 = object.messageLine2 || '';
    this.messageLine3 = object.messageLine3 || '';
    this.messageLine4 = object.messageLine4 || '';
    this.messageLine5 = object.messageLine5 || '';
    this.messageLine6 = object.messageLine6 || '';
    this.messageLine7 = object.messageLine7 || '';
    this.messageLine8 = object.messageLine8 || '';
    this.messageLine9 = object.messageLine9 || '';
    this.messageLine10 = object.messageLine10 || '';
    this.messageLine11 = object.messageLine11 || '';
    this.messageLine12 = object.messageLine12 || '';
    this.messageLine13 = object.messageLine13 || '';
    this.messageLine14 = object.messageLine14 || '';
    this.messageLine15 = object.messageLine15 || '';
    this.messageLine16 = object.messageLine16 || '';
    this.messageLine17 = object.messageLine17 || '';
    this.messageLine18 = object.messageLine18 || '';
    this.messageLine19 = object.messageLine19 || '';
    this.messageLine20 = object.messageLine20 || '';
    this.stdCpkRate = object.stdCpkRate || '';
  }
}
