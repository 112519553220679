export default class CustomerInfomation {
  constructor(existingObject) {
    const object = existingObject || {};

    this.fleetAccountNumber = object.fleetAccountNumber || undefined;
    this.clientName = object.clientName || undefined;
    this.clientEffectiveDate = object.clientEffectiveDate || '';
    this.contactPersonName = object.contactPersonName || '';
    this.contactPersonEmail = object.contactPersonEmail || '';
    this.contactPersonPhoneNumber = object.contactPersonPhoneNumber || '';
    this.prepaidOrCredit = object.prepaidOrCredit || null;
    this.accountStatus = object.accountStatus || null;
  }
}
