export default class ProductSetupItemBase {
  constructor(existingObject) {
    const object = existingObject || {};

    this.index = object.index;
    this.productCode = object.productCode || undefined;
    this.productDescription = object.productDescription || 'Unknown';
    this.instalmentFee = object.instalmentFee || '0.0';
    this.instalmentMonth = object.instalmentMonth || '0';
    this.serviceFee = object.serviceFee || '0.0';
    this.effectiveDate = object.effectiveDate || '';
    this.driverAllow = object.driverAllow || '';
    this.driverBill = object.driverBill || '';
    this.systemInd = object.systemInd || '';
  }
}
