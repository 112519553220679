import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      light: {
        primary: '#0033A1',
        secondary: '#0089FF',
        accent: '#0A2240',
        error: '#D0021B',
        info: '#0089FF',
        success: '#00AD6C',
        warning: '#F86700',
      },
      dark: {
        primary: '#0033A1',
        secondary: '#0089FF',
        accent: '#0A2240',
        error: '#D0021B',
        info: '#0089FF',
        success: '#00AD6C',
        warning: '#F86700',
      },
    },
  },
});
