import ContactDetails from './ContactDetails';

export default class DriverDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.fullname = object.fullname || undefined;
    this.contactDetails = new ContactDetails(object.contactDetails);
  }
}
