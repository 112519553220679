import JobCardItemBase from './JobCardItemBase';

export default class JobCardItemBattery extends JobCardItemBase {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || {};

    this.warranty = object.warranty || undefined;
    this.warrantyPeriod = object.warrantyPeriod || undefined;
  }
}
