export default class ManagedAuthorisation {
  constructor(existingObject) {
    const object = existingObject || {};

    this.authType = object.authType || {
      isFms: false,
      isClient: false,
    };

    this.isRequired = object.isRequired !== undefined ? object.isRequired : false;
    this.authorisationNumber = object.authorisationNumber || undefined;
    this.authorisationDate = object.authorisationDate || undefined;
    this.authorisationTime = object.authorisationTime || undefined;
    this.estimateRepairDate = object.estimateRepairDate || undefined;
    this.estimateRepairTime = object.estimateRepairTime || undefined;
    this.collectedBy = object.collectedBy || undefined;
    this.collectedDate = object.collectedDate || undefined;
    this.collectedTime = object.collectedTime || undefined;
    this.isVehicleOnRoad = object.isVehicleOnRoad !== undefined ? object.isVehicleOnRoad : false;
    this.documents = object.documents || [];
  }
}
