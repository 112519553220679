import ProductSetupItemBase from './ProductSetupItemBase';

export default class ProductSetupItemValueAdd extends ProductSetupItemBase {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || {};

    this.productCode = object.productCode || undefined;
  }
}
