export default class MerchantSetupItemBase {
  constructor(existingObject) {
    const object = existingObject || {};

    this.index = object.index;
    this.merchantTypeCode = object.merchantTypeCode || undefined;
    this.merchantTypeDescription = object.merchantTypeDescription || 'Unknown';
    this.dailyTransCountLimitDFLT = object.dailyTransCountLimitDFLT || '0.0';
    this.dailyTransCountLimitOVRD = object.dailyTransCountLimitOVRD || '0';
    this.minHoursDFLT = object.minHoursDFLT || '0';
    this.minHoursOVRD = object.minHoursOVRD || '0';
    this.transactionValueLimitDefault = object.transactionValueLimitDefault || '0.0';
    this.transactionValueLimitOverride = object.transactionValueLimitOverride || '0.0';
  }
}
