import Location from './Location';

export default class VehicleDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.odometerReading = object.odometerReading || undefined;
    this.location = new Location(object.location);
  }
}
