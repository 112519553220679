import { USER_ROLES } from '../config/constants';

export default class User {
  constructor(existingObject) {
    const object = existingObject || {};

    this.username = object.username || undefined;
    this.displayName = object.displayName || 'Unknown';
    this.emailAddress = object.emailAddress || undefined;
    this.groups = object.groups || [];
    this.userIds = object.userIds || [];
  }

  // /////////////////
  // CALL CENTRE ROLES
  get isCallCentre() {
    return USER_ROLES.callCentre.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isCallCentreManager() {
    return this.groups.includes(USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager']);
  }

  get isCallCentreTeamLeader() {
    return this.groups.includes(USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader']);
  }

  get isCallCentreTeamMember() {
    return this.groups.includes(USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member']);
  }

  // //////////////
  // COMPLIANCE ROLES
  get isCompliance() {
    return USER_ROLES.compliance.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isComplianceManager() {
    return this.groups.includes(USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Manager']);
  }

  get isComplianceTeamLeader() {
    return this.groups.includes(USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Team Leader']);
  }

  get isComplianceTeamMember() {
    return this.groups.includes(USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Team Member']);
  }

  // //////////////
  // MERCHANT ROLES
  get isMerchantInternal() {
    return USER_ROLES.merchantInternal.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isMerchantInternalManager() {
    return this.groups.includes(USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager']);
  }

  get isMerchantInternalTeamLeader() {
    return this.groups.includes(USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader']);
  }

  get isMerchantInternalTeamMember() {
    return this.groups.includes(USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member']);
  }

  // //////////////
  // PAYMENTS ROLES
  get isPayments() {
    return USER_ROLES.payments.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isPaymentsManager() {
    return this.groups.includes(USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Manager']);
  }

  get isPaymentsTeamLeader() {
    return this.groups.includes(USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Leader']);
  }

  get isPaymentsTeamMember() {
    return this.groups.includes(USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Member']);
  }

  // //////////////
  // MERCHANT ROLES
  get isMerchant() {
    return USER_ROLES.merchantExternal.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isMerchantSuperUser() {
    return this.groups.includes(USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Superuser']);
  }

  get isMerchantManager() {
    return this.groups.includes(USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Manager']);
  }

  get isMerchantTeamMember() {
    return this.groups.includes(USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Team Member']);
  }

  // //////////////
  // CUSTOMER ROLES
  get isCustomer() {
    return USER_ROLES.customer.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isCustomerSuperUser() {
    return this.groups.includes(USER_ROLES.customer.roles['MyBlueFleet - Customer Superuser']);
  }

  get isCustomerManager() {
    return this.groups.includes(USER_ROLES.customer.roles['MyBlueFleet - Customer Manager']);
  }

  get isCustomerTeamMember() {
    return this.groups.includes(USER_ROLES.customer.roles['MyBlueFleet - Customer Team Member']);
  }

  // //////////////
  // CUSTOMER ROLES
  get isCardOperations() {
    return USER_ROLES.cardOperations.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isUserRoleCardOperations() {
    return this.groups.includes(USER_ROLES.cardOperations.roles['MyBlueFleet - MMU Operations Team Member']);
  }

  // FUND OPERATIONS
  get isFundOperations() {
    return USER_ROLES.fundOperations.groups.filter((x) => this.groups.includes(x)).length > 0;
  }

  get isFundOperationsTeamLeader() {
    return this.groups.includes(USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Leader']);
  }

  get isFundOperationsTeamMember() {
    return this.groups.includes(USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Member']);
  }
}
