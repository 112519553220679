export default class RemittanceAdvice {
  constructor(existingObject) {
    const object = { ...existingObject };

    this.paymentDate = object.paymentDate || undefined;
    this.merchantId = object.merchantId || undefined;
    this.originatingMerchantId = object.originatingMerchantId || undefined;
    this.jobNumber = object.jobNumber || undefined;
    this.dealerReferenceNumber = object.dealerReferenceNumber || undefined;
    this.registrationNumber = object.registrationNumber || undefined;
    this.authorisationNumber = object.authorisationNumber || undefined;
    this.authorisationDate = object.authorisationDate || undefined;
    this.authorisationAmount = object.authorisationAmount || undefined;
    this.paymentTotal = object.paymentTotal || undefined;
    this.vatAmount = object.vatAmount || undefined;
    this.commission = object.commission || undefined;
    this.paymentAmount = object.paymentAmount || undefined;
    this.chargebackReason = object.chargebackReason || undefined;
    this.bankAccount = object.bankAccount || undefined;
    this.filename = object.filename || undefined;
    this.fileData = object.fileData || undefined;
    this.fileContentType = object.fileContentType || undefined;
  }
}
