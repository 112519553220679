import ReferenceList from './ReferenceList';
import TechnicianDetails from './TechnicianDetails';
import TowingAssistanceDetails from './TowingAssistanceDetails';

export default class Task {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.taskInfo = {};
    this.serviceRequestId = object.serviceRequestId || undefined;
    this.createdTimestamp = object.createdTimestamp || undefined;
    this.closedTimestamp = object.closedTimestamp || undefined;
    this.externalReferenceId = object.externalReferenceId || undefined;
    this.externalReferenceType = object.externalReferenceType || undefined;
    this.severity = object.severity || undefined;
    this.status = new ReferenceList(object.status);
    this.state = new ReferenceList(object.state);
    this.technicianDetails = new TechnicianDetails(object.technicianDetails);
    this.towingAssistanceDetails = new TowingAssistanceDetails(object.towingAssistanceDetails);
    this.notes = object.notes || [];
  }
}
