import EventSnapshot from './EventSnapshot';

export default class Event {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.referenceId = object.referenceId || undefined;
    this.referenceType = object.referenceType || undefined;
    this.event = object.event || undefined;
    this.timestamp = object.timestamp || undefined;
    this.severity = object.severity || undefined;
    this.username = object.username || 'Unknown';
    this.eventType = object.eventType || undefined;
    this.comments = object.comments || [];
    this.snapshot = new EventSnapshot(object.snapshot);
  }
}
