export default class FundAllocationDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.journals = object.journals || [];
    this.oemEmailAddress = object.oemEmailAddress || undefined;
    this.declineReasons = object.declineReasons || [];
    this.status = object.status || undefined;
    this.journalAction = object.journalAction || '';
    this.refReg = object.refReg || undefined;
    this.documentsReceived = object.documentsReceived || false;
  }
}
