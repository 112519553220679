import Attachment from './Attachment';
import JobCardItemBase from './JobCardItemBase';

export default class JobCardItemShock extends JobCardItemBase {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || {};

    this.shockTestReport = new Attachment(object.shockTestReport);
    this.shockTestParameters = object.shockTestParameters || undefined;
  }
}
