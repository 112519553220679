import AuthMerchant from './AuthMerchant';

export default class MerchantDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.serviceOfferings = object.serviceOfferings || [];
    this.merchant = new AuthMerchant(object.merchant);
  }
}
