export default {
  data() {
    return {
      currency: {
        decimal: '.',
        thousands: ',',
        prefix: 'R ',
        suffix: '',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      negativeCurrency: {
        decimal: '.',
        thousands: ',',
        prefix: 'R ',
        suffix: '',
        precision: 2,
        masked: false,
        valueRange: {

        },
      },
    };
  },
};
