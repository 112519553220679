export default class CollectionDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.collectionDate = object.collectionDate || undefined;
    this.collectionTime = object.collectionTime || undefined;
    this.confirmCollection = object.confirmCollection !== undefined ? object.confirmCollection : false;
    this.collectedBy = object.collectedBy || undefined;
  }
}
