import CustomerInfomation from './CustomerInfomation';

export default class VehicleCaptureDetails {
  constructor(existingObject) {
    const object = existingObject || {};

    this.vehicles = object.vehicles || [];
    this.customerInfomation = new CustomerInfomation(object.customerInfomation);
    this.confirmation = object.confirmation || '';
    this.hasErrors = object.hasErrors || false;
    this.requestType = object.requestType || '';
  }
}
