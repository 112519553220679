import * as Bowser from 'bowser';
import rest from '@/plugins/rest';
import store from '@/store';
import { getCurrentTimestamp } from '@/utils';

export default class ErrorEvent {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.username = object.username || 'Unknown';
    this.type = object.type || 'WEB';
    this.error = object.error || undefined;
    this.stacktrace = object.stacktrace || undefined;
    this.timestamp = object.timestamp || getCurrentTimestamp();
    this.version = object.version || process.env.VUE_APP_VERSION;
    this.context = object.context || undefined;
    this.metadata = object.metadata || undefined;
    this.clientDetails = object.clientDetails || undefined;
    this.ipAddress = object.ipAddress || undefined;
  }

  async sendError(err, context, metadata) {
    try {
      const error = err;

      // Get the client browser details
      const browser = Bowser.getParser(window.navigator.userAgent);

      // Get current logged in user
      const currentUser = store.getters['session/user'];

      // Strip sensitive info from error message
      if (error && error.config) {
        delete error.config.headers.Authorization;
      }

      // Default to using the error as metadata if metadata was not sent
      const errorMetadata = metadata ? JSON.stringify(metadata) : JSON.stringify(error);

      this.username = currentUser.username || 'Unknown';
      this.error = error.message;
      this.stacktrace = error.stack;
      this.metadata = errorMetadata;
      this.clientDetails = JSON.stringify(browser.getResult());
      this.context = context;

      store.dispatch('logs/addError', this);

      await rest.post('/errors', this);

      return Promise.resolve();
    } catch {
      return Promise.resolve();
    }
  }
}
