export default class ReferenceList {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.key = object.key || undefined;
    this.value = object.value || undefined;
    this.description = object.description || undefined;
    this.disabled = object.disabled !== undefined ? object.disabled : false;
    this.hidden = object.hidden !== undefined ? object.hidden : false;
    this.parentId = object.parentId || undefined;
    this.selected = object.selected !== undefined ? object.selected : false;
    this.default = object.default !== undefined ? object.default : false;
  }
}
