export default class VehicleInformation {
  constructor(existingObject) {
    const object = existingObject || {};

    this.centreNumber = object.centreNumber || '';
    this.engineNumber = object.engineNumber || '';
    this.vinNumber = object.vinNumber || '';
    this.chasisNumber = object.chasisNumber || '';
    this.registerationNumber = object.registerationNumber || '';
    this.contractStartDate = object.contractStartDate || '';
    this.territory = object.territory || '';
    this.budgetDate = object.budgetDate || '';
    this.finalContractKm = object.finalContractKm || '';
    this.contractMonths = object.contractMonths || '';
    this.vehicleApplicationCode = object.vehicleApplicationCode || '';
    this.tyresImported = object.tyresImported || '';
    this.frontTyreSize = object.frontTyreSize || '';
    this.frontTyreQuantity = object.frontTyreQuantity || '';
    this.rearTyreSize = object.rearTyreSize || '';
    this.rearTyreQuantity = object.rearTyreQuantity || '';
    this.vehicleRegistrationDate = object.vehicleRegistrationDate || '';
    this.vehicleGroup = object.vehicleGroup || '';
    this.vehicleId = object.vehicleId || '';
    this.vehicleEffectiveDate = object.vehicleEffectiveDate || '';
    this.vehicleType = object.vehicleType || '';
    this.vehicleMake = object.vehicleMake || '';
    this.vehicleYear = object.vehicleYear || '';
    this.vehicleModel = object.vehicleModel || '';
    this.vehicleDescription = object.vehicleDescription || '';
    this.vehicleCategory = object.vehicleCategory || '';
    this.modelExtension = object.modelExtension || '';
    this.transmission = object.transmission || '';
    this.fuelType = object.fuelType || '';
    this.colour = object.colour || '';
    this.serviceUnit = object.serviceUnit || '';
    this.cmanufactureFuelTankCapacity = object.manufactureFuelTankCapacity || '';
    this.isCustomCapacityKnown = object.isCustomCapacityKnown || '';
    this.customFuelCapacity = object.customFuelCapacity || '';
    this.isNewOrUsed = object.isNewOrUsed || '';
    this.openingOdometer = object.openingOdometer || '';
    this.startOdometer = object.startOdometer || '';
    this.mmuOpeningOdometer = object.mmuOpeningOdometer || '';
    this.mmuOpeningOdometerDate = object.mmuOpeningOdometerDate || '';
    this.lastService = object.lastService || '';
    this.sellingDealer = object.sellingDealer || '';
    this.salePerson = object.salePerson || '';
    this.warrantyExistence = object.warrantyExistence || '';
    this.warrantyExpityDate = object.warrantyExpityDate || '';
    this.warrantyKilometers = object.warrantyKilometers || '';
    this.planType = object.planType || '';
    this.planExpiryDate = object.planExpiryDate || '';
    this.planKilometers = object.planKilometers || '';
    this.status = object.status || '';
    this.requestType = object.requestType || '';
    this.rentalType = object.rentalType || '';
    this.description = object.description || '';
    this.cofCertificate = object.cofCertificate || '';
    this.cofExpiryMonth = object.cofExpiryMonth || '';
    this.id = object.id || '';
    this.isn = object.isn || '';
    this.liveDateAndTime = object.liveDateAndTime || '';
  }
}
