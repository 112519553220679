import VehicleInformation from './VehicleInformation';
import VehicleSetup from './VehicleSetup';
import ProductSetup from './ProductSetup';
import CardDelivery from './CardDelivery';
import DriverInformation from './DriverInformation';
import MaintananceContractSetup from './MaintananceContractSetup';

export default class Vehicle {
  constructor(existingObject) {
    const object = existingObject || {};

    this.vehicleInformation = new VehicleInformation(object.vehicleInformation);
    this.vehicleSetup = new VehicleSetup(object.vehicleSetup);
    this.productSetup = new ProductSetup(object.productSetup);
    this.cardDelivery = new CardDelivery(object.cardDelivery);
    this.driverInformation = new DriverInformation(object.driverInformation);
    this.maintananceContractSetup = new MaintananceContractSetup(object.maintananceContractSetup);
    this.validationErrors = object.validationErrors || [];
    this.hasErrors = object.hasErrors || false;
    this.requestStatus = object.requestStatus || '';
  }
}
