import JobCardItemBase from './JobCardItemBase';

export default class JobCardItemBrake extends JobCardItemBase {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || {};

    this.serialNumber = object.serialNumber || undefined;
  }
}
