export default class WorkItem {
  constructor(existingObject) {
    const object = existingObject || {};

    this.key = object.key || undefined;
    this.value = object.value || undefined;
    this.description = object.description || undefined;
    this.hidden = object.hidden !== undefined ? object.hidden : true;
    this.default = object.default !== undefined ? object.default : false;
    this.selected = object.selected !== undefined ? object.selected : false;
  }
}
