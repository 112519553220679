import JournalKeyValue from './JournalKeyValue';
import ClientJournal from './ClientJournal';
import FundJournal from './FundJournal';

export default class Journal {
  constructor(existingObject) {
    const object = existingObject || {};

    this.journalEntityType = new JournalKeyValue(object.journalEntityType);
    this.clientJournal = new ClientJournal(object.clientJournal);
    this.fundJournal = new FundJournal(object.fundJournal);
    this.narration = object.narration || undefined;
    this.journalDate = object.journalDate || undefined;
    this.isDuplicate = object.isDuplicate || false;
    this.isAcknowledged = object.isAcknowledged || false;
    this.validationErrors = object.validationErrors || [];
    this.batchNumber = object.batchNumber || undefined;
    this.isValid = object.isValid || false;
    this.status = object.status || undefined;
    this.refReg = object.refReg || undefined;
  }
}
