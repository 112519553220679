export default class DriverInformation {
  constructor(existingObject) {
    const object = existingObject || {};

    this.driverAllowance = object.driverAllowance || '';
    this.driverInitials = object.driverInitials || '';
    this.driverName = object.driverName || '';
    this.driverID = object.driverID || '';
    this.dateOfBirth = object.dateOfBirth || '';
    this.foreignPassportNumber = object.foreignPassportNumber || '';
    this.passportCountry = object.passportCountry || '';
    this.driverBilling = object.driverBilling || '';
    this.allowanceTypes = object.allowanceTypes || '';
    this.amount = object.amount || '';
    this.excessAboveAllowance = object.excessAboveAllowance || '';
    this.paymentMethod = object.paymentMethod || '';
    this.acbDomicile = object.acbDomicile || '';
    this.account = object.account || '';
    this.accountType = object.accountType || '';
    this.accountName = object.accountName || '';
    this.proofOfbanking = object.proofOfbanking || [];
    this.fuel = object.fuel || '';
    this.topUpOil = object.topUpOil || '';
    this.accidentRepair = object.accidentRepair || '';
    this.tollPackingFees = object.tollPackingFees || '';
    this.miscellaneous = object.miscellaneous || '';
    this.fundingInterest = object.fundingInterest || '';
    this.transactionCharges = object.transactionCharges || '';
    this.captureCharge = object.captureCharge || '';
    this.fleetSaver = object.fleetSaver || '';
    this.adminFee = object.adminFee || '';
    this.turnKeyFee = object.turnKeyFee || '';
    this.lostCardIn = object.lostCardIn || '';
    this.lostCardCharge = object.lostCardCharge || '';
    this.lostWalletCharge = object.lostWalletCharge || '';
    this.maintananceFund = object.maintananceFund || '';
    this.allowanceFee = object.allowanceFee || '';
    this.stampDuty = object.stampDuty || '';
    this.transferFee = object.transferFee || '';
    this.shortTermRental = object.shortTermRental || '';
    this.refundBankCharge = object.refundBankCharge || '';
    this.cellphoneNumber = object.cellphoneNumber || '';
    this.countryCode = object.countryCode || '';
  }
}
