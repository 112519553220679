import AuthorisationApproval from './AuthorisationApproval';
import AuthorisationInvoice from './AuthorisationInvoice';
import CollectionDetails from './CollectionDetails';
import JobCard from './JobCard';
import ServiceRating from './ServiceRating';

export default class Authorisation {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.approvals = new AuthorisationApproval(object.approvals);
    this.requestDate = object.requestDate || undefined;
    this.requestTime = object.requestTime || undefined;
    this.collectionDetails = new CollectionDetails(object.collectionDetails);
    this.jobCard = new JobCard(object.jobCard);
    this.invoice = new AuthorisationInvoice(object.invoice);
    this.serviceRating = new ServiceRating(object.serviceRating);
    this.linkedServiceRequestNumber = object.serviceRequestId || undefined;
  }
}
