import ContractualClient from './ContractualClient';
import JournalKeyValue from './JournalKeyValue';

export default class FundJournal {
  constructor(existingObject) {
    const object = existingObject || {};

    this.typeOfJournal = new JournalKeyValue(object.typeOfJournal);
    this.typeOfTransfer = new JournalKeyValue(object.typeOfTransfer);
    this.incomeAmount = object.incomeAmount || '0.00';
    this.expensesAmount = object.expensesAmount || '0.00';
    this.interestAmount = object.interestAmount || '0.00';
    this.from = new ContractualClient(object.from);
    this.to = new ContractualClient(object.to);
  }
}
