import Task from './Task';
import ServiceRequest from './ServiceRequest';

export default class EventSnapshot {
  constructor(existingObject) {
    const object = existingObject || {};

    if (object.taskData) {
      this.taskData = new Task(object.taskData);
    }

    if (object.serviceRequestData) {
      this.serviceRequestData = new ServiceRequest(object.serviceRequestData);
    }
  }
}
