import JobCardItemBase from './JobCardItemBase';

export default class JobCardItemTyre extends JobCardItemBase {
  constructor(existingObject) {
    super(existingObject);

    const object = existingObject || {};

    this.serialNumber = object.serialNumber || undefined;
    this.position = object.position || undefined;
    this.treadDepth = object.treadDepth || undefined;
  }
}
