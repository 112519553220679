import ContactDetails from './ContactDetails';

export default class Merchant {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || '';
    this.name = object.name || '';
    this.address = object.address || '';
    this.contactDetails = new ContactDetails(object.contactDetails);
  }
}
