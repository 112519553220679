/* eslint-disable class-methods-use-this */
export default class JobCardItemBase {
  constructor(existingObject) {
    const object = existingObject || {};

    this.index = object.index;
    this.partNo = object.partNo || undefined;
    this.description = object.description || 'Unknown';
    this.qty = object.qty || 1;
    this.retailPriceExclVat = object.retailPriceExclVat || '0.0';
    this.discountPercent = object.discountPercent || '0';
    this.specialPriceExclVat = object.specialPriceExclVat || '0.0';
    this.netAmountExclVat = object.netAmountExclVat || '0.0';
    this.totalVatAmount = object.totalVatAmount || '0.0';
    this.totalAmount = object.totalAmount || '0.0';
    this.totalNetAmount = object.totalNetAmount || '0.0';
    this.manufacturerPartNumber = object.manufacturerPartNumber || undefined;
  }

  cleanValueNumberString(valueString) {
    // eslint-disable-next-line no-restricted-globals
    return (!isNaN(valueString))
      ? parseFloat(valueString).toFixed(2)
      : parseFloat(Number(valueString.replace(/[^0-9|\\.]/g, ''))).toFixed(2);
  }

  toFixedAmounts() {
    this.qty = `${this.qty}`;
    this.retailPriceExclVat = this.cleanValueNumberString(this.retailPriceExclVat);
    this.discountPercent = this.cleanValueNumberString(this.discountPercent);
    this.netAmountExclVat = this.cleanValueNumberString(this.netAmountExclVat);
    this.totalVatAmount = this.cleanValueNumberString(this.totalVatAmount);
    this.totalAmount = this.cleanValueNumberString(this.totalAmount);
    this.specialPriceExclVat = this.cleanValueNumberString(this.specialPriceExclVat);
    this.totalNetAmount = this.cleanValueNumberString(this.totalNetAmount);
  }
}
