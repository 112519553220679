import CostCentre from './CostCentre';

export default class Client {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.number = object.number || undefined;
    this.name = object.name || 'Unknown';
    this.costCentre = new CostCentre(object.costCentre);
  }
}
