export default class ContractualClient {
  constructor(existingObject) {
    const object = existingObject || {};

    this.clientNumber = object.clientNumber || undefined;
    this.billingCentre = object.billingCentre || undefined;
    this.fundNumber = object.fundNumber || undefined;
    this.vehicleNumber = object.vehicleNumber || undefined;
  }
}
