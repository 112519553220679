import Commission from './Commission';

export default class ClientCommission {
  constructor(existingObject) {
    const object = existingObject || {};

    this.merchantId = object.merchantId || '';
    this.fleetClientNumber = object.fleetClientNumber || '';
    this.fleetClientName = object.fleetClientName || '';
    this.jvSettlementDiscount = new Commission(object.jvSettlementDiscount) || undefined;
    this.annualTurnover = object.annualTurnover || '0.0';
    this.numberOfVehicles = object.numberOfVehicles || '0';
  }
}
