import Customer from './Customer';

export default class Vehicle {
  constructor(existingObject) {
    const object = existingObject || {};

    this.registrationNumber = object.registrationNumber || 'Pending';
    this.vehicleIdentificationNumber = object.vehicleIdentificationNumber || 'Pending';
    this.year = object.year || undefined;
    this.mmCode = object.mmCode || undefined;
    this.make = object.make || undefined;
    this.model = object.model || undefined;
    this.axleType = object.axleType || undefined;
    this.mass = object.mass || undefined;
    this.odometerReading = object.odometerReading || undefined;
    this.client = new Customer(object.client);
    this.fundNumber = object.fundNumber || undefined;
  }
}
