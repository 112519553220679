import ContactDetails from './ContactDetails';

export default class AuthMerchant {
  constructor(existingObject) {
    const object = existingObject || {};

    this.merchantNumber = object.merchantNumber || '';
    this.merchantName = object.merchantName || '';
    this.contactDetails = new ContactDetails(object.contactDetails);
  }
}
