import axios from 'axios';

import { getAccessToken } from '@/workers/session.worker';

const rest = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, */*',
  },
  timeout: 30000,
});

// Add interceptor to inject token on all rest calls from memory
rest.interceptors.request.use(async (restConfig) => {
  const config = restConfig;
  const token = await getAccessToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default rest;
