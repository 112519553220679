export default class VehicleSetup {
  constructor(existingObject) {
    const object = existingObject || {};

    this.usage = object.usage || '';
    this.servmainPlainType = object.servmainPlainType || '';
    this.planExpiryDate = object.planExpiryDate || '';
    this.managedMaintanance = object.managedMaintanance || '';
    this.planEndKms = object.planEndKms || '';
    this.engineNumber = object.engineNumber || '';
    this.vinNumber = object.vinNumber || '';
    this.chasisNumber = object.chasisNumber || '';
    this.registerNumber = object.registerNumber || '';
    this.contractStartDate = object.contractStartDate || '';
    this.territory = object.territory || '';
    this.budgetDate = object.budgetDate || '';
    this.finalContractKm = object.finalContractKm || '';
    this.contractMonths = object.contractMonths || '';
    this.vehicleApplicationCode = object.vehicleApplicationCode || '';
    this.tyresImported = object.tyresImported || '';
    this.frontTyreSize = object.frontTyreSize || '';
    this.frontTyreQuantity = object.frontTyreQuantity || '';
    this.rearTyreSize = object.rearTyreSize || '';
    this.rearTyreQuantity = object.rearTyreQuantity || '';
  }
}
