import CommissionPercentage from './CommissionPercentage';

export default class Commission {
  constructor(existingObject) {
    const object = existingObject || {};

    this.previous = new CommissionPercentage(object.previous) || undefined;
    this.current = new CommissionPercentage(object.current) || undefined;
    this.future = new CommissionPercentage(object.future) || undefined;
  }
}
