export default class AuthorisationApproval {
  constructor(existingObject) {
    const object = existingObject || {};

    this.status = object.status || undefined;
    this.reason = object.reason || undefined;
    this.timestamp = object.timestamp || undefined;
    this.username = object.username || undefined;
  }
}
