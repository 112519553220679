export default class Pagination {
  constructor(existingObject) {
    const object = { ...existingObject };

    this.number = parseInt(`${object.number || 1}`, 10);
    this.numberOfElements = parseInt(`${object.numberOfElements || 4}`, 10);
    this.totalPages = parseInt(`${object.totalPages || 1}`, 10);
    this.totalElements = parseInt(`${object.totalElements || 0}`, 10);
  }
}
