export default class UserId {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || undefined;
    this.companyName = object.companyName || undefined;
    this.userType = object.userType || undefined;
    this.selected = object.selected || false;
  }
}
