export default class ClientJournal {
  constructor(existingObject) {
    const object = existingObject || {};

    this.transferAmount = object.transferAmount || '0.00';
    this.vatIndicator = object.vatIndicator || undefined;
    this.transactionCode = object.transactionCode || undefined;
    this.glAccountNumber = object.glAccountNumber || undefined;
    this.clientNumber = object.clientNumber || undefined;
    this.billingCentre = object.billingCentre || undefined;
  }
}
