import ReferenceList from './ReferenceList';

export default class AuthorisationInvoice {
  constructor(existingObject) {
    const object = existingObject || {};

    this.documents = object.documents || [];
    this.status = new ReferenceList(object.status);
    this.reasons = object.reasons || [];
  }
}
