import { mapObject } from '@/utils/index';
import Commission from './Commission';
import ClientCommission from './ClientCommission';

export default class CommissionCharge {
  constructor(existingObject) {
    const object = existingObject || {};

    const clients = object.clientCommissions || { commissions: [] };
    clients.commissions = mapObject(clients.commissions, ClientCommission);

    this.merchantCommission = new Commission(object.merchantCommission) || undefined;
    this.normalCommission = new Commission(object.normalCommission) || undefined;
    this.clientCommission = mapObject(object.clientCommissions, ClientCommission);
    this.capturedBy = object.capturedBy || '';
    this.approvedBy = object.approvedBy || '';
    this.annualTurnover = object.annualTurnover || '';
    this.effectiveDate = object.effectiveDate || '';
  }
}
